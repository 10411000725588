import { Button, Divider, Drawer, Stack } from "@mantine/core";
import { useContext } from "react";
import { Logout } from "tabler-icons-react";

import { AuthContext } from "#root/context/AuthenticationContext";
import AdvancedView from "./Views/AdvancedView";

type HeaderDrawerProps = {
  opened: boolean;
  toggleOpened: (opened: boolean) => void;
};

const HeaderDrawer = ({ opened, toggleOpened }: HeaderDrawerProps) => {
  const { logoutFirebase } = useContext(AuthContext);

  return (
    <Drawer
      opened={opened}
      onClose={() => toggleOpened(false)}
      title="VISTA AVANZADA"
      padding={"md"}
      size={"xl"}
      position="right"
    >
      <Stack>
        <Stack sx={{ padding: "0px 20px" }}>
          <AdvancedView section={"drawer"} toggleOpened={toggleOpened} />
        </Stack>
        <Divider size="xs" />
        <Button
          size="sm"
          onClick={() => logoutFirebase()}
          leftIcon={<Logout size={16} />}
          variant="outline"
        >
          Cerrar sesión
        </Button>
      </Stack>
    </Drawer>
  );
};

export default HeaderDrawer;
