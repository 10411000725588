import { REACT_APP_ENDPOINT } from "#root/config";
import { createClient } from "urql";

const getToken = () => localStorage.getItem("token");

const client = createClient({
  url: REACT_APP_ENDPOINT,
  fetchOptions: () => {
    const token = getToken();
    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    };
  },
});

export default client;
