import {
  createStyles,
  Header,
  Group,
  Container,
  Burger,
  Stack,
  Tooltip,
  Button,
  ActionIcon,
  Image,
  Box,
} from "@mantine/core";
import { useBooleanToggle } from "@mantine/hooks";
import { useContext } from "react";
import { Logout } from "tabler-icons-react";

import { AuthContext } from "#root/context/AuthenticationContext";
import NviLogo from "#root/images/NviLogo";
import NVIisotipo from "#root/images/NVIisotipo.svg";
import AdvancedView from "./Views/AdvancedView";
import HeaderDrawer from "./Drawer";
import ViewSelector from "./ViewSelector";

const useStyles = createStyles(theme => ({
  header: {
    padding: 20,
    [theme.fn.smallerThan("xs")]: {
      padding: 15,
    },
  },
  logoContainer: {
    [theme.fn.smallerThan("lg")]: {
      display: "flex",
      flexDirection: "row",
      gap: 20,
    },
  },
  logoutIconBtn: {
    display: "none",
    [theme.fn.smallerThan("xl")]: {
      display: "block",
    },
  },
  logoutBtn: {
    display: "block",
    [theme.fn.smallerThan("xl")]: {
      display: "none",
    },
  },
  hideIconBtn: {
    [theme.fn.smallerThan("lg")]: {
      display: "none",
    },
  },
  burger: {
    [theme.fn.smallerThan("lg")]: {
      display: "block",
    },
  },
  logo: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },
  isotipo: {
    display: "none",
    [theme.fn.smallerThan("xs")]: {
      display: "block",
    },
  },
}));

type HeaderProps = {
  view: string;
  setView: (view: string) => void;
};

const HeaderNvi = ({ view, setView }: HeaderProps) => {
  const { classes, theme } = useStyles();
  const [opened, toggleOpened] = useBooleanToggle(false);
  const { logoutFirebase, email } = useContext(AuthContext);

  const isMinsaUser = email.includes("minsa");
  return (
    <>
      <Header height={"auto"} className={classes.header}>
        <Container size={1400} p={0}>
          <Group position="apart">
            <Stack spacing={10} align="center" className={classes.logoContainer}>
              <Image width={50} src={NVIisotipo} alt="NVI Logo" className={classes.isotipo} />
              <Box className={classes.logo}>
                <NviLogo scale={0.3} />
              </Box>
              {!isMinsaUser && <ViewSelector view={view} setView={setView} section="header" />}
            </Stack>
            {view === "advance" && <AdvancedView section={"header"} />}
            <Stack spacing={0}>
              <Tooltip label="Salir">
                <ActionIcon
                  variant="outline"
                  color={theme.colors.brand[2]}
                  onClick={() => logoutFirebase()}
                  className={`${classes.logoutIconBtn} ${
                    view === "simple" ? "" : classes.hideIconBtn
                  }`}
                >
                  <Logout size={16} />
                </ActionIcon>
              </Tooltip>
              <Button
                size="sm"
                onClick={() => logoutFirebase()}
                leftIcon={<Logout size={16} />}
                variant="outline"
                className={classes.logoutBtn}
              >
                Salir
              </Button>
              {view === "advance" && (
                <Burger
                  opened={opened}
                  onClick={() => toggleOpened()}
                  className={isMinsaUser ? "" : classes.burger}
                  sx={{ display: "none" }}
                  size="sm"
                  mr={"sm"}
                />
              )}
            </Stack>
          </Group>
        </Container>
      </Header>
      <HeaderDrawer opened={opened} toggleOpened={toggleOpened} />
    </>
  );
};

export default HeaderNvi;
