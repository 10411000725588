import { enhanceReduxMiddleware } from "kepler.gl/middleware";
import keplerGlReducer from "kepler.gl/reducers";
import { combineReducers, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./app-reducer";

const customizedKeplerGlReducer = keplerGlReducer.initialState({
  uiState: {
    currentModal: null,
  },
});

const reducer = combineReducers({
  keplerGl: customizedKeplerGlReducer,
  app: appReducer,
});

const middlewares = enhanceReduxMiddleware([]);
const enhancers = [applyMiddleware(...middlewares)];

const store = configureStore({
  reducer,
  middleware: middlewares,
  preloadedState: {},
  enhancers: enhancers,
});

export default store;
/* 
import { enhanceReduxMiddleware } from "kepler.gl/middleware";
import keplerGlReducer from "kepler.gl/reducers";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";

import appReducer from "./app-reducer";

const reducers = combineReducers({
  keplerGl: keplerGlReducer,
  app: appReducer,
});

const middlewares = enhanceReduxMiddleware([]);
const enhancers = [applyMiddleware(...middlewares)];

export default createStore(reducers, {}, compose(...enhancers)); */
