import { Box, Select, createStyles } from "@mantine/core";
import { ChevronDown } from "tabler-icons-react";

const useStyles = createStyles(theme => ({
  container: {
    borderRadius: 5,
    /*  backgroundColor: theme.colors.gray[2], */
    /*  border: `1px solid ${theme.colors.gray[2]}`, */
  },
  containerDesktop: {
    maxWidth: 180,
    [theme.fn.smallerThan("xs")]: {
      maxWidth: 170,
    },
  },
}));

type ViewSelectorProps = {
  view: string;
  setView: (view: string) => void;
  section: string;
};

const ViewSelector = ({ view, setView, section }: ViewSelectorProps) => {
  const { classes } = useStyles();

  return (
    <Box
      px={10}
      className={`${classes.container} ${section === "header" && classes.containerDesktop}`}
    >
      <Select
        label="Seleccionar vista:"
        value={view}
        onChange={setView}
        data={[
          { value: "simple", label: "Simple" },
          { value: "advance", label: "Avanzada" },
        ]}
        rightSection={<ChevronDown size={14} />}
        styles={{
          rightSection: { pointerEvents: "none" },
        }}
      />
    </Box>
  );
};

export default ViewSelector;
