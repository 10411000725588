import { render } from "react-dom";
import App from "./components/App";
import { nviBrand } from "./theming";
import { Provider as ReduxProvider } from "react-redux";
import { MantineProvider } from "@mantine/core";
import { Provider as UrqlProvider } from "urql";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import client from "./lib/client";
import { NotificationsProvider } from "@mantine/notifications";

render(
  <BrowserRouter>
    <UrqlProvider value={client}>
      <ReduxProvider store={store}>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            fontFamily: "JetBrains Mono, monospace",
            colors: {
              brand: nviBrand,
            },
            primaryColor: "brand",
          }}
        >
          <NotificationsProvider>
            <App />
          </NotificationsProvider>
        </MantineProvider>
      </ReduxProvider>
    </UrqlProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
