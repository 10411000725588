import { processRowObject } from "kepler.gl/processors";

type Props = {
  selectedKind: string;
  dataMetadata: any[];
  formateDatasetTitle: (selectedKind: string) => string;
};

const datasetConfig = ({ selectedKind, dataMetadata, formateDatasetTitle }: Props) => {
  const bluepointConfig = {
    version: "v1",
    config: {
      visState: {
        layers: [
          {
            /* id: "v59xyrd", */
            type: "point",
            config: {
              dataId: `${selectedKind}_${dataMetadata.length}`,
              label: "Point",
              color: [255, 203, 153],
              highlightColor: [252, 242, 26, 255],
              columns: {
                lat: "lat",
                lng: "lng",
                altitude: null,
              },
              isVisible: true,
              visConfig: {
                radius: 10,
                fixedRadius: false,
                opacity: 0.8,
                outline: false,
                thickness: 2,
                strokeColor: null,
                colorRange: {
                  name: "Custom Palette",
                  type: "custom",
                  category: "Custom",
                  colors: ["#1dafff", "#b0a9b0", "#FFC300"],
                },
                radiusRange: [0, 50],
                filled: true,
              },
              textLabel: [
                {
                  field: null,
                  color: [255, 255, 255],
                  size: 18,
                  offset: [0, 0],
                  anchor: "start",
                  alignment: "center",
                },
              ],
            },
            visualChannels: {
              colorField: {
                name: "risk",
                type: "string",
              },
              colorScale: "ordinal",
              strokeColorField: null,
              strokeColorScale: "quantile",
              sizeField: null,
              sizeScale: "linear",
            },
          },
        ],
      },
    },
  };

  const vectorpointConfig = {
    version: "v1",
    config: {
      visState: {
        layers: [
          {
            /* id: "i4zznpg", */
            type: "point",
            config: {
              dataId: `${selectedKind}_${dataMetadata.length}`,
              label: "Point",
              color: [136, 87, 44],
              highlightColor: [252, 242, 26, 255],
              columns: {
                lat: "lat",
                lng: "lng",
                altitude: null,
              },
              isVisible: true,
              visConfig: {
                radius: 10,
                fixedRadius: false,
                opacity: 0.8,
                outline: false,
                thickness: 2,
                strokeColor: null,
                colorRange: {
                  name: "ColorBrewer YlOrRd-5",
                  type: "sequential",
                  category: "ColorBrewer",
                  colors: ["#ffffb2", "#fecc5c", "#fd8d3c", "#f03b20", "#bd0026"],
                },
                strokeColorRange: {
                  name: "Global Warming",
                  type: "sequential",
                  category: "Uber",
                  colors: ["#5A1846", "#900C3F", "#C70039", "#E3611C", "#F1920E", "#FFC300"],
                },
                radiusRange: [0, 50],
                filled: true,
              },
              hidden: false,
              textLabel: [
                {
                  field: null,
                  color: [255, 255, 255],
                  size: 18,
                  offset: [0, 0],
                  anchor: "start",
                  alignment: "center",
                },
              ],
            },
            visualChannels: {
              colorField: {
                name: "risk",
                type: "integer",
              },
              colorScale: "quantize",
              strokeColorField: null,
              strokeColorScale: "quantile",
              sizeField: null,
              sizeScale: "linear",
            },
          },
        ],
      },
    },
  };

  const infectionmodelConfig = {
    version: "v1",
    config: {
      visState: {
        layers: [
          {
            /* id: "jrty0or", */
            type: "point",
            config: {
              dataId: `${selectedKind}_${dataMetadata.length}`,
              label: "Point",
              color: [255, 203, 153],
              highlightColor: [252, 242, 26, 255],
              columns: { lat: "lat", lng: "lng", altitude: null },
              isVisible: true,
              visConfig: {
                radius: 10,
                fixedRadius: false,
                opacity: 0.8,
                outline: false,
                thickness: 2,
                strokeColor: null,
                colorRange: {
                  name: "ColorBrewer YlOrRd-5",
                  type: "sequential",
                  category: "ColorBrewer",
                  colors: ["#ffffb2", "#fecc5c", "#fd8d3c", "#f03b20", "#bd0026"],
                },
                strokeColorRange: {
                  name: "Global Warming",
                  type: "sequential",
                  category: "Uber",
                  colors: ["#5A1846", "#900C3F", "#C70039", "#E3611C", "#F1920E", "#FFC300"],
                },
                radiusRange: [0, 50],
                filled: true,
              },
              hidden: false,
              textLabel: [
                {
                  field: null,
                  color: [255, 255, 255],
                  size: 18,
                  offset: [0, 0],
                  anchor: "start",
                  alignment: "center",
                },
              ],
            },
            visualChannels: {
              colorField: { name: "probability", type: "real" },
              colorScale: "quantile",
              strokeColorField: null,
              strokeColorScale: "quantile",
              sizeField: null,
              sizeScale: "linear",
            },
          },
        ],
      },
    },
  };

  const inspectionConfig = {
    version: "v1",
    config: {
      visState: {
        layers: [
          {
            /*  id: "62jhtl8", */
            type: "point",
            config: {
              dataId: `${selectedKind}_${dataMetadata.length}`,
              label: "Point",
              color: [231, 159, 213],
              highlightColor: [252, 242, 26, 255],
              columns: { lat: "lat", lng: "lng", altitude: null },
              isVisible: true,
              visConfig: {
                radius: 10,
                fixedRadius: false,
                opacity: 0.8,
                outline: false,
                thickness: 2,
                strokeColor: null,
                colorRange: {
                  name: "Custom Palette",
                  type: "custom",
                  category: "Custom",
                  colors: ["#3177ea", "#000000", "#ffffff", "#0ed527", "#00fff6"],
                },
                strokeColorRange: {
                  name: "Global Warming",
                  type: "sequential",
                  category: "Uber",
                  colors: ["#5A1846", "#900C3F", "#C70039", "#E3611C", "#F1920E", "#FFC300"],
                },
                radiusRange: [0, 50],
                filled: true,
              },
              hidden: false,
              textLabel: [
                {
                  field: null,
                  color: [255, 255, 255],
                  size: 18,
                  offset: [0, 0],
                  anchor: "start",
                  alignment: "center",
                },
              ],
            },
            visualChannels: {
              colorField: { name: "status", type: "string" },
              colorScale: "ordinal",
              strokeColorField: null,
              strokeColorScale: "quantile",
              sizeField: null,
              sizeScale: "linear",
            },
          },
        ],
      },
    },
  };

  const sprayedConfig = {
    version: "v1",
    config: {
      visState: {
        layers: [
          {
            /* id: "r4e56zw", */
            type: "point",
            config: {
              dataId: `${selectedKind}_${dataMetadata.length}`,
              label: "Point",
              color: [255, 203, 153],
              highlightColor: [252, 242, 26, 255],
              columns: { lat: "lat", lng: "lng", altitude: null },
              isVisible: true,
              visConfig: {
                radius: 9.7,
                fixedRadius: false,
                opacity: 0.8,
                outline: true,
                thickness: 3.2,
                strokeColor: null,
                colorRange: {
                  name: "Custom Palette",
                  type: "custom",
                  category: "Custom",
                  colors: ["#000000", "#e5e2d9"],
                  //Estos colores se deben aplicar cuando existan los 4 estados en la data (closed,comprehensive_treatment,reluctanted,sprayed)
                  /* colors: ["#f40534", "#000000", "#f10cf1", "#e5e2d9"], */
                },
                strokeColorRange: {
                  name: "Custom Palette",
                  type: "custom",
                  category: "Custom",
                  colors: ["#16cd42", "#fee805", "#000000"],
                },
                radiusRange: [0, 50],
                filled: true,
              },
              hidden: false,
              textLabel: [
                {
                  field: null,
                  color: [255, 255, 255],
                  size: 18,
                  offset: [0, 0],
                  anchor: "start",
                  alignment: "center",
                },
              ],
            },
            visualChannels: {
              colorField: { name: "status", type: "string" },
              colorScale: "ordinal",
              strokeColorField: { name: "result", type: "string" },
              strokeColorScale: "ordinal",
              sizeField: null,
              sizeScale: "linear",
            },
          },
        ],
      },
    },
  };

  const sampleconfig = (kind: string) => {
    switch (kind) {
      case "bluepoint_mod":
        return bluepointConfig;
      case "vectorpoint_mod":
        return vectorpointConfig;
      case "infection_model":
        return infectionmodelConfig;
      case "inspection":
        return inspectionConfig;
      case "sprayed":
        return sprayedConfig;
      default:
        return undefined;
    }
  };

  return {
    datasets: {
      info: {
        label: formateDatasetTitle(selectedKind),
        id: `${selectedKind}_${dataMetadata.length}`,
      },
      data: processRowObject(dataMetadata),
    },
    config: sampleconfig(selectedKind),
    options: {
      keepExistingConfig: true, //keep old datasets and config layers
    },
  };
};
export default datasetConfig;
