import { Routes, Route } from "react-router-dom";
import Login from "../Pages/Auth/Login";
import Observatory from "../Pages/Observatory/Observatory";
import AuthProvider from "#root/context/AuthenticationContext";
import RequireAuth from "../Pages/Auth/RequiredAuth";
import PublicRoute from "../Pages/Auth/PublicRoute";
import { Navigate } from "react-router-dom";

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<RequireAuth />}>
          <Route path="/" element={<Navigate replace to="observatory" />} />
          <Route path="observatory" element={<Observatory />} />
        </Route>
        <Route element={<PublicRoute />}>
          <Route path="login" element={<Login />} />
        </Route>
        <Route path="*" element={<Navigate replace to="login" />} />
      </Routes>
    </AuthProvider>
  );
};

export default App;
